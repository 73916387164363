/* Reset default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure body and html take up full width, allow height to expand */
html, body {
  height: auto; /* Allow height to expand */
  width: 100%;
  margin: 0;
  background-color: #1c1c1c;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: auto; /* Allow vertical scrolling */
}

/* Allow the root element to expand and enable scrolling */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Default font styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Styling for code elements */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #12c099; /* Green color for the scrollbar thumb */
  border-radius: 10px; /* Rounded edges for the scrollbar thumb */
}

::-webkit-scrollbar-track {
  background-color: #1c1c1c; /* Background color of the scrollbar track */
  border-radius: 10px; /* Rounded edges for the scrollbar track */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Makes the scrollbar thin */
  scrollbar-color: #12c099 #1c1c1c; /* Thumb color and track color for Firefox */
}

/* PageContainer styling */
.PageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh; /* Allow content to expand */
  flex-grow: 1;
  padding-bottom: 60px; /* Adds space for the footer */
  overflow-y: auto; /* Ensure the container can scroll vertically */
}

/* Footer styling */
.Footer {
  padding: 1.5rem;
  margin-top: auto;
  color: #fff;
  text-align: center;
  width: 100%;
}

/* Remove Google's default InfoWindow close button */
.gm-style-iw-c {
  display: none !important;
}
